import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Modal, Table, message, Space, notification } from 'antd'
import type { ADMIN_REPORT, COGNITO_GRP_DT } from 'types'
import { PageTitle } from 'components'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNotifications } from 'hooks'
import { assignUserGroups, listAdminReport } from 'services'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface GroupColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface AssignUserGroupModalProps {
  visible: boolean
  groups: COGNITO_GRP_DT[]
  onClose: () => void
}

const AssignReportsFromListGroupsModal: React.FC<AssignUserGroupModalProps> = ({ visible, groups, onClose }) => {
  const [loading, setLoading] = useState(false)
  const { addNotification } = useNotifications()
  const [reports, setReports] = useState<ADMIN_REPORT[]>([])
  const [selectedReports, setSelectedReports] = useState<ADMIN_REPORT[]>([])
  const handleSelect = (e: CheckboxChangeEvent, selectedReport: ADMIN_REPORT) => {
    if (e.target.checked) {
      setSelectedReports(prev => [...prev, selectedReport])
    } else {
      setSelectedReports(prev => prev.filter(report => report.report_id !== selectedReport.report_id))
    }
  }
  const userReportsColumns: GroupColumn[] = [
    {
      key: 'id',
      title: 'Select',
      dataIndex: 'id',
      render: (_: any, record: any) => <Checkbox style={{
      }} onChange={e => { handleSelect(e, record) }} />
    },
    {
      title: 'Report Name',
      dataIndex: 'report_name',
      key: 'report_name',
      sorter: (a, b) => a.report_name.localeCompare(b.report_name)
    },
    {
      title: 'Report Description',
      dataIndex: 'report_desc',
      key: 'report_desc',
      responsive: ['lg']
    }
  ]

  async function handleAssignSelectedReports (): Promise<void> {
    if (selectedReports.length <= 0) {
      void message.error('Select report to assign')
    } else {
      try {
        setLoading(true)
        const payload = {
          reports: selectedReports,
          groups
        }
        const msg = await assignUserGroups(payload)
        addNotification({
          id: Date.now(),
          type: 'success',
          message: `${msg}`,
          timestamp: Date.now()
        })
        void message.success(msg)
        onClose()
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error assigning the selected reports to the user groups')
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfReports = await listAdminReport()
        setReports(listOfReports.reports)
      } catch (error) {
        console.error('Error fetching the users', error)
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting users',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="Available Quicksight Reports" />
      {
                (selectedReports && selectedReports.length > 0)
                  ? <>
                        <Space>
                            <Button style={{
                              color: '#d67632'
                            }} loading={loading} type="link" onClick={handleAssignSelectedReports}>Assign</Button>
                        </Space>
                    </>
                  : null
            }
      <Table
        dataSource={reports}
        columns={userReportsColumns}
        rowKey="report_id"
        bordered
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default AssignReportsFromListGroupsModal
